import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledWrapper = styled.div`
  position: relative;
  animation-delay: ${({ index }) => `${index*100}ms`};
  figure {
    @media screen and (max-width: 769px) {
      max-width: 60%;
      margin: 0 auto;
    }
    img {
      border-radius: 290486px;
    }
  }
  h3 {
    margin-top: 1em;
  }
`
export default function Author({ author, index }) {
  const subtitle = [
    author.short, author.country
  ].filter(s => s && s.length > 0)
    .join(' | ');

  return (
    <StyledWrapper className="column is-4 animate__animated animate__fadeIn has-text-centered" index={index}>
      <Link to={`/authors/${author.slug}`}>
        <figure className="image is-fullwidth">
          <Img
            fadeIn
            fluid={author.logo.guid.localFile?.childImageSharp?.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={author.title}
          />
        </figure>
        <h3 className="title is-4">
          {author.title}
        </h3>
        {subtitle && (
          <p className="subtitle">
            {subtitle}
          </p>
        )}
      </Link>
    </StyledWrapper>
  )
}
Author.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    short: PropTypes.string,
    logo: PropTypes.shape({
      guid: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              src: PropTypes.string,
            })
          })
        })
      })
    })
  }).isRequired,
  index: PropTypes.number.isRequired
}
