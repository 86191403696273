import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../layouts"
import Author from "../components/author"

export default function Authors() {
  const { authors } = useStaticQuery(
    graphql`
        query {
            authors: allWordpressWpArtist(
                sort: {fields: [priority, title]}
                filter: {wordpress_id: {ne: 201}}
            ) {
                nodes {
                    id: wordpress_id
                    title
                    slug
                    country
                    short
                    logo {
                        guid {
                            localFile {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 400
                                        maxHeight: 400
                                        cropFocus: CENTER
                                    ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return (
    <Layout title="Authors" description="Our beloved authors">
      <div className="container is-widescreen has-text-centered">
        <h1 className="title has-text-primary">
          Authors
        </h1>
        <p className="subtitle">
          Some of our beloved authors
        </p>
        <div className="columns is-multiline">
          {authors.nodes.map((author, index) => (
            <Author
              index={index}
              key={author.id}
              author={author}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}